<template>
  <BaseModal content-class="max-w-[560px]" :open="isOpen" @close="isOpen = false">
    <template v-if="isOpen">
      <div class="gamma mb-6" data-jest="title">
        Uusi toivelista
      </div>
      <form
        class="grid gap-6"
        novalidate
        @submit.prevent="createWishlistHandler"
      >
        <BaseInput
          v-model="form.name"
          :validator="v$.name"
          name="createWishlist"
          label="Toivelistan nimi"
        />
        <BaseButton :state="isSaving ? 'loading' : 'default'" type="submit" data-jest="submit">
          <template #default>
            Luo toivelista
          </template>
          <template #loading>
            <EllipsisLoadingIcon />
          </template>
        </BaseButton>
      </form>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import BaseInput from '~/components/BaseInput.vue'
import BaseButton from '~/components/BaseButton.vue'
import BaseModal from '~/components/BaseModal.vue'
import { EllipsisLoadingIcon } from '~/components/icon'

const { t } = useI18n()
const store = useUserStore()
const form = reactive({ name: '' })
const { $emitter } = useNuxtApp()
const isOpen = ref(false)
const isSaving = ref(false)

const rules: any = {
  name: { required: helpers.withMessage(t('validator.required'), required) }
}
const v$ = useVuelidate(rules, form)

onMounted(() => {
  $emitter.on('wishlist:open-menu:create', (open: boolean) => {
    isOpen.value = open
  })
})

onBeforeUnmount(() => {
  $emitter.off('wishlist:open-menu:create')
})

const resetForm = () => {
  v$.value.$reset()
  form.name = ''
}

const createWishlistHandler = async () => {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) return
  isSaving.value = true
  const newWishlist = await store.createWishlist({ name: form.name })
  if (newWishlist) {
    $emitter.emit('wishlist:created', newWishlist)
    isOpen.value = false
    isSaving.value = false
    resetForm()
  }
  else {
    isSaving.value = false
  }
}
</script>
